import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import DatetimeRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";

class DateTimeRangePicker extends Component {
    constructor(props){
        super(props);
        let now = new Date();
        let start = this.props.startDate|| moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let end = this.props.endDate|| moment(start).add(1, "days").subtract(1, "seconds");
        this.state = {
            start : start,
            end : end,
            ranges: {
              'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Today': [moment(), moment()],              
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
              'This Month': [moment().startOf('month'), moment().subtract(1, 'days')],
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              'This Year': [moment().startOf('year'), moment().endOf('year')],   
          }
          
        }
        this.applyCallback = this.applyCallback.bind(this);
    }
    applyCallback(event, picker) {
        this.setState({
          start: picker.startDate,
          end: picker.endDate,
        });
        this.props.applyCallback(event,picker);
      }
    render(){
        let start = this.state.start.format('MMM D, YYYY');
        let end = this.state.end.format('MMM D, YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
        label = start;
        }        
            return(
                <div style={{float: 'right', paddingLeft: '5px'}}>
                <DatetimeRangePicker
            startDate={this.state.start}
            endDate={this.state.end}
            ranges={this.state.ranges}
            onApply={this.applyCallback}
            applyClass={'btn btn-success'}
            cancelClass={'btn btn-danger'}
            opens={this.props.position || ''}
            drops={this.props.dropdown || ''}
            showDropdowns={true}
            linkedCalendars={false}
          > <a href="javascript:void(0)" className="selected-date-range-btn" >
              <div className="pull-left">
                <i className="fa fa-calendar"/>                
                  {
                    this.props.isDateShow? <span> {  label} </span>:null
                }
              </div>
              
            </a>
          </DatetimeRangePicker>                    
                </div>
            );
        }
}
export default DateTimeRangePicker;